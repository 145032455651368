import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Button from "react-bootstrap/Button";
import { Form, Row, Col, Modal } from "react-bootstrap";
import jwt from "jsonwebtoken";
import { getWithAuth } from "../redux/actions/axios/securedAxios";
import "./Logs.css";

const LogSearch = () => {
  const history = useHistory();
  const [level, setLevel] = useState();
  const [logs, setLogs] = useState([]);
  const [selectedMessage, setSelectedMessage] = useState(null); // State for the selected log message
  const [showModal, setShowModal] = useState(false); // State to control modal visibility

  useEffect(() => {
    const token = jwt.decode(localStorage.getItem("authToken"));
    if (!token || (token.role !== "worker" && token.role !== "manager")) {
      history.push("/login");
    } else {
      searchLogs();
    }
  }, [level]);

  const searchLogs = async () => {
    const params2 = { level: level };
    const { data } = await getWithAuth("/api/logs", params2);
    if (data !== undefined) {
      const sortedLogs = data.sort((firstLog, secondLog) =>
        firstLog.datetime < secondLog.datetime ? 1 : -1
      );
      setLogs(sortedLogs !== undefined ? sortedLogs : []);
    }
  };

  const levelChange = (event) => {
    event.target.value !== "undefined"
      ? setLevel(event.target.value)
      : setLevel();
  };

  const getTime = (datetime) => {
    const newDate = new Date(datetime);
    const minutes = newDate.getMinutes();
    const hours = newDate.getHours();
    const date = newDate.getDate();
    const month = newDate.getMonth() + 1;
    const year = newDate.getFullYear().toString().slice(-2);

    return `${date < 10 ? `0${date}` : `${date}`}/${
      month < 10 ? `0${month}` : `${month}`
    }/${year} ${hours < 10 ? `0${hours}` : `${hours}`}:${
      minutes < 10 ? `0${minutes}` : `${minutes}`
    }`;
  };

  const handleRowClick = (message) => {
    setSelectedMessage(message); // Set the selected log message
    setShowModal(true); // Show the modal
  };

  const handleCloseModal = () => {
    setShowModal(false); // Hide the modal
    setSelectedMessage(null); // Clear the selected message
  };

  return (
    <div>
      <div className="card">
        <h6 className="card-header">Logs</h6>
        <div className="text-center">
          <Form>
            <Row className="mb-1">
              <Form.Group as={Col}>
                <Form.Label>Level: </Form.Label>
                <select defaultValue={undefined} onChange={levelChange}>
                  <option value="undefined">Qualsevol</option>
                  <option value="INFO">INFO</option>
                  <option value="ERROR">ERROR</option>
                </select>
              </Form.Group>
            </Row>
          </Form>

          <p />
          <div className="text-center">
            <Button
              variant="primary"
              onClick={() => {
                searchLogs();
              }}
            >
              BUSCAR
            </Button>
          </div>
          <table className="table">
            <thead className="thead-dark">
              <tr>
                <th className="text-center">Date</th>
                <th className="text-center">Level</th>
                <th className="text-center">Topic</th>
                <th className="text-center">Mesage</th>
              </tr>
            </thead>
            <tbody>
              {logs ? (
                logs.map((log) => (
                  <tr
                    key={log._id}
                    onClick={() => handleRowClick(log.message)} // Handle row click
                    style={{ cursor: "pointer" }} // Add pointer cursor for better UX
                  >
                    <td className="text-center">{getTime(log.datetime)}</td>
                    <td className="text-center">{log.level}</td>
                    <td className="text-center">{log.topic}</td>
                    <td className="text-center">
                      {log.message.length > 10
                        ? `${log.message.substring(0, 10)}...`
                        : log.message}
                    </td>
                  </tr>
                ))
              ) : (
                <div className="text-center">No hi ha resultats</div>
              )}
            </tbody>
          </table>
        </div>
      </div>
      {/* Modal to display the full message */}
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Full Log Message</Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-body-scrollable">
          {selectedMessage}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default LogSearch;
