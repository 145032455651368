import * as actionTypes from "../constants/orderConstants";
import {
  getWithAuth,
  putWithAuth,
  postWithAuth,
} from "../actions/axios/securedAxios";

export const getOrderDetail = (id) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.GET_ORDERS_REQUEST });

    const { data } = await getWithAuth(`/api/orders/${id}`, {});

    dispatch({
      type: actionTypes.GET_ORDERS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: actionTypes.GET_ORDERS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getOrders = (props) => async (dispatch) => {
  const id = props !== undefined && props.id !== "" ? props.id : undefined;
  const code =
    props !== undefined && props.code !== "" ? props.code : undefined;
  const customer = props !== undefined ? props.customer : undefined;
  const fromDate =
    props !== undefined && props.fromDate !== undefined
      ? props.fromDate
      : allTime();
  const toDate =
    props !== undefined && props.toDate !== undefined
      ? props.toDate
      : undefined;
  const status = props !== undefined ? props.status : undefined;
  const paymentType = props !== undefined ? props.paymentType : undefined;
  const store = props !== undefined ? props.store : undefined;

  try {
    dispatch({ type: actionTypes.GET_ORDERS_REQUEST });

    const { data } = await postWithAuth("/api/orders/request", {
      customer: customer,
      paymentType: paymentType,
      datetime: {
        $gt: fromDate,
        $lte: toDate,
      },
      status: {
        $in: status,
      },
      code: code,
      id: id,
      store: store,
    });
    dispatch({
      type: actionTypes.GET_ORDERS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: actionTypes.GET_ORDERS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getSales = (props) => async (dispatch) => {
  const id = props !== undefined && props.id !== "" ? props.id : undefined;
  const code =
    props !== undefined && props.code !== "" ? props.code : undefined;
  const customer = props !== undefined ? props.customer : undefined;
  const fromDate =
    props !== undefined && props.fromDate !== undefined
      ? props.fromDate
      : allTime();
  const toDate =
    props !== undefined && props.toDate !== undefined
      ? props.toDate
      : undefined;
  const status = props !== undefined ? props.status : undefined;
  const paymentType = props !== undefined ? props.paymentType : undefined;
  const store = props !== undefined ? props.store : undefined;

  try {
    dispatch({ type: actionTypes.GET_SALES_REQUEST });

    const { data } = await postWithAuth("/api/orders/request", {
      customer: customer,
      paymentType: paymentType,
      datetime: {
        $gt: fromDate,
        $lte: toDate,
      },
      status: {
        $in: status,
      },
      code: code,
      id: id,
      store: store,
    });

    let sales = [];
    if (data !== undefined && data.length > 0) {
      data.forEach((order) => {
        order.lines.forEach((line) => {
          const itemKey =
            line.itemTitle === "HAMBURGUESA AL GUST" ? 0 : line.itemId;
          if (!sales[itemKey]) {
            sales[itemKey] = {
              item: line.itemTitle,
              price: line.price * parseInt(line.quantity),
              quantity: parseInt(line.quantity),
            };
          } else {
            sales[itemKey] = {
              item: line.itemTitle,
              price:
                sales[itemKey].price + line.price * parseInt(line.quantity),
              quantity: sales[itemKey].quantity + parseInt(line.quantity),
            };
          }
        });
      });
    }

    dispatch({
      type: actionTypes.GET_SALES_SUCCESS,
      payload: sales,
    });
  } catch (error) {
    dispatch({
      type: actionTypes.GET_SALES_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const createOrder = (order) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.CREATE_ORDER_REQUEST });

    const { data } = await postWithAuth("/api/orders", {
      order: order,
    });

    dispatch({
      type: actionTypes.CREATE_ORDER_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: actionTypes.CREATE_ORDER_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const updateOrderStatus = (id, newOrderStatus) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.UPDATE_ORDER_STATUS_REQUEST });

    const { data } = await putWithAuth("/api/orders", {
      id: id,
      newOrderStatus: newOrderStatus,
    });

    dispatch({
      type: actionTypes.UPDATE_ORDER_STATUS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: actionTypes.UPDATE_ORDER_STATUS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

const allTime = () => {
  const date = new Date();
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = "2022";

  return (
    year +
    "-" +
    ("0" + month).slice(-2) +
    "-" +
    ("0" + day).slice(-2) +
    "T00:00:00.000Z"
  );
};
