import axios from "axios";
import * as actionTypes from "../constants/productConstants";
import { putWithAuth, postWithAuth } from "../actions/axios/securedAxios";

export const getProducts = (props) => async (dispatch) => {
  const store = props !== undefined ? props.store : undefined;
  const type = props !== undefined ? props.type : undefined;
  const active = props !== undefined ? props.active : undefined;
  const id = props !== undefined ? props.id : undefined;
  const services =
    props !== undefined && props.service !== undefined
      ? props.service
      : ["restaurant", "take away"];

  try {
    dispatch({ type: actionTypes.GET_PRODUCTS_REQUEST });

    const { data } = await postWithAuth("/api/products/request", {
      id: id,
      store: store,
      type: type,
      active: active,
      services: {
        $in: services,
      },
    });

    dispatch({
      type: actionTypes.GET_PRODUCTS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: actionTypes.GET_PRODUCTS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getProductDetails = (id) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.GET_PRODUCT_DETAILS_REQUEST });

    const { data } = await axios.get(`/api/products/${id}`);

    dispatch({
      type: actionTypes.GET_PRODUCT_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: actionTypes.GET_PRODUCT_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const updateAvailability = (product) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.UPDATE_PRODUCT_REQUEST });

    const { data } = await putWithAuth(`/api/products/${product.id}`);

    dispatch({
      type: actionTypes.UPDATE_PRODUCT_REQUEST,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: actionTypes.UPDATE_PRODUCT_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const removeProductDetails = () => (dispatch) => {
  dispatch({ type: actionTypes.GET_PRODUCT_DETAILS_RESET });
};
