import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Order from "../components/Order";
import { getOrders as listOrders } from "../redux/actions/orderActions";
import jwt from "jsonwebtoken";
import "./MyOrders.css";

const MyOrders = () => {
  const dispatch = useDispatch();
  const getOrders = useSelector((state) => state.getOrders);
  const { orders, loading, error } = getOrders;

  useEffect(() => {
    const token = jwt.decode(localStorage.getItem("authToken"));
    const customer =
      token != null && token !== undefined ? token.email : undefined;
    const status = ["Pagat", "Rebuda"];
    dispatch(listOrders({ customer, status }));
  }, [dispatch]);

  return (
    <div>
      <table className="table table-striped">
        <thead className="thead-dark">
          <tr>
            <th className="text-center">Núm</th>
            <th className="text-center">Dia</th>
            <th className="text-center">Preu</th>
            <th className="text-center">Punts</th>
          </tr>
        </thead>
        <tbody>
          {loading ? (
            <tr>
              <td>
                <div className="spinner-border m-5" role="status">
                  <span className="sr-only" />
                </div>
              </td>
            </tr>
          ) : error ? (
            <tr>
              <td>{error}</td>
            </tr>
          ) : orders ? (
            orders.map((order) => (
              <Order key={order._id} order={order} type={"myOrders"} />
            ))
          ) : (
            <tr>
              <td>No hi ha resultats</td>
            </tr>
          )}
        </tbody>
      </table>
      <div className="text-center">
        {orders !== undefined && orders.length < 1 && !loading
          ? "No hi ha resultats"
          : ""}
      </div>
    </div>
  );
};

export default MyOrders;
