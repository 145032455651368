import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import ListGroup from "react-bootstrap/ListGroup";
import jwt from "jsonwebtoken";

const Profile = () => {
  const history = useHistory();
  const [role, setRole] = useState();

  useEffect(() => {
    const token = localStorage.getItem("authToken");
    if (
      token !== undefined &&
      token !== "undefined" &&
      token !== null &&
      token !== "null" &&
      jwt.decode(token) !== undefined
    ) {
      setRole(jwt.decode(token).role);
    } else {
      logout();
    }
  }, []);

  const logout = () => {
    localStorage.removeItem("authToken");
    history.push("/login");
  };

  return (
    <div>
      {role === "worker" || role === "manager" ? (
        <div className="card">
          <h6 className="card-header">BEIVE </h6>
          <div>
            <ListGroup as="ul">
              <ListGroup.Item as="li">
                <Link to="/backoffice" style={{ textDecoration: "none" }}>
                  Adminsitració
                </Link>
              </ListGroup.Item>
            </ListGroup>
          </div>
        </div>
      ) : (
        <div></div>
      )}

      <div className="card">
        <h6 className="card-header">EL MEU PERFIL </h6>
        <ListGroup.Item as="li">
          <Link to="/myUser" style={{ textDecoration: "none" }}>
            Les meves dades
          </Link>
        </ListGroup.Item>
        <ListGroup.Item as="li">
          <Link to="/myOrders" style={{ textDecoration: "none" }}>
            Les meves comandes
          </Link>
        </ListGroup.Item>
        <div className="d-grid gap-2">
          <Button size="lg" onClick={logout}>
            LOGOUT
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Profile;
