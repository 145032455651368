import axios from "axios";
const jwt = require("jsonwebtoken");

const tokenKey = "authToken";

const getAccessToken = () => {
  return localStorage.getItem(tokenKey);
};

const setAccessToken = (token) => {
  localStorage.setItem(tokenKey, token);
};

const isTokenExpired = async () => {
  const token = getAccessToken();
  if (token === undefined) {
    localStorage.removeItem("authToken");
  }
  const decoded = jwt.decode(token);
  return !decoded || decoded.exp <= Date.now() / 1000;
};

const refreshAccessToken = async () => {
  const token = getAccessToken();
  const body = { token };
  const { data } = await axios.post("/api/auth/refresh", body, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  setAccessToken(data.newToken);
  return data.newToken;
};

const getAuthHeaders = async () => {
  if (await isTokenExpired()) {
    const newToken = await refreshAccessToken();
    return { Authorization: `Bearer ${newToken}` };
  }
  return { Authorization: `Bearer ${getAccessToken()}` };
};

export const postWithAuth = async (url, body) => {
  try {
    const headers = await getAuthHeaders();
    const response = await axios.post(url, body, { headers });
    return response;
  } catch (error) {
    console.error("Error postWithAuth: " + JSON.stringify(error));
    throw error;
  }
};

export const getWithAuth = async (url, params) => {
  try {
    const headers = await getAuthHeaders();
    let config = {
      headers: headers,
      params,
    };
    const response = await axios.get(url, config);
    return response;
  } catch (error) {
    console.error("Error getWithAuth: " + JSON.stringify(error));
    throw error;
  }
};

export const putWithAuth = async (url, body) => {
  try {
    const headers = await getAuthHeaders();
    const response = await axios.put(url, body, { headers });
    return response;
  } catch (error) {
    console.error("Error putWithAuth: " + JSON.stringify(error));
    throw error;
  }
};
